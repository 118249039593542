<template>
    <div class="c-medication__table editable_table flex flex-col w-full">
        <v-data-table
            ref="groupsTable"
            single-select
            :item-key="itemKey"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            :item-class="(item) => (item.active ? 'v-data-table__selected' : '')"
            class="a-table w-full"
            data-cy="medicationsList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
        >
            <template class="" v-slot:item.startDate="{item}">
                <DatePickerCell
                    :ref="`startDate-${item.eventId}`"
                    v-model="item.startDate"
                    :disabled="disabled"
                    :isRequired="true"
                    label="dd/mm/yyyy"
                    :rules="[required]"
                    :cell-id="item.eventId"
                    :edit-active="item.active"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.startTime="{item}">
                <DatetimePickerCell
                    :ref="`startTime-${item.eventId}`"
                    :edit-active="item.active"
                    :cell-id="item.eventId"
                    v-model="item.startTime"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.group="{item}">
                <SelectCell
                    :ref="`group-${item.eventId}`"
                    :edit-active="item.active"
                    :items="sortItems(types)"
                    label="Group"
                    :editableComponent="'v-autocomplete'"
                    :isRequired="true"
                    :rules="[required]"
                    v-model="item.group"
                    :cell-id="item.eventId"
                    :displayValue="getDisplayValue(item.group, types, 'text')"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.locationProgramId="{item}">
                <SelectCell
                    :ref="`siteSection-${item.eventId}`"
                    :edit-active="item.active"
                    :items="sortItems(locationProgramItems)"
                    label="Site-Section"
                    :editableComponent="'v-autocomplete'"
                    :isRequired="true"
                    :rules="[required]"
                    v-model="item.locationProgramId"
                    :cell-id="item.eventId"
                    :displayValue="getDisplayLocationProgram(item.locationProgramId, locationProgramItems, 'text')"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.numAttendees="{item}">
                <EditableNumberCell
                    :ref="`num_attendees-${item.eventId}`"
                    v-model="item.numAttendees"
                    :disabled="disabled"
                    label="Num_attendees"
                    :isRequired="true"
                    inputType="number"
                    :cell-id="item.eventId"
                    :edit-active="item.active"
                    @edit-row="toggleEdit"
                />
            </template>

            <template class="" v-slot:item.name="{item}">
                <EditableCell
                    :ref="`name-${item.eventId}`"
                    v-model="item.name"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Name"
                    :cell-id="item.eventId"
                    :edit-active="item.active"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        class="mx-1 bg-red-light"
                        @click.prevent="deleteHandler(item)"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>

                    <a
                        href="#"
                        v-if="item.active"
                        class="mr-1 bg-orange-light"
                        @click.prevent="cancelHandler"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        class="hover:text-primary bg-primary-lightest"
                        @click.prevent="saveHandler(item)"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addNewRow"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { DataTableHelper, EditableDataTableHelper } from '@/components/shared/mixins/editableDataTableMixin'
import StatusBadge from '@/components/partials/StatusBadge'
import {format} from 'date-fns'
import DatePickerCell from '@/components/partials/DatePickerCell'
import EditableCell from '@/components/partials/EditableCell'
import EditableNumberCell from '@/components/partials/EditableNumberCell'
import TimePickerCell from '@/components/partials/TimePickerCell'
import DELETE_GROUPS from '@/graphql/mutations/deleteGroups.gql'
import INSERT_GROUP from '@/graphql/mutations/insertGroups.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import cloneDeep from 'clone-deep'
import SelectCell from '@/components/partials/SelectCell'
import sortItems from '@/components/shared/mixins/sortItems'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { SharedMixin } from '@/helpers/displayValue'

export default {
    name: 'GroupsTable',
    mixins: [DataTableHelper, EditableDataTableHelper, MessageDialog, sortItems, FormRules, SharedMixin],
    components: {StatusBadge, DatePickerCell, EditableCell, EditableNumberCell, SelectCell, TimePickerCell, DatetimePickerCell},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            emptyText: 'No groups found for the search criteria',
            duration: 3000,
            type: '',
            message: '',
            tableDataArr: [],
            itemKey: 'eventId',
            itemsPerPage: 10,
            page: 1,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                {
                    text: 'Date',
                    align: 'left',
                    value: 'startDate',
                    class: 'w-130 start-date--header'
                },
                {
                    text: 'Time',
                    align: 'left',
                    value: 'startTime',
                    class: 'w-240 home--header'
                },
                {
                    text: 'Group',
                    align: 'left',
                    value: 'group',
                    class: 'w-200 relationship--header'
                },
                {
                    text: 'Facilitator',
                    align: 'left',
                    value: 'name',
                    class: 'w-240 home--header'
                },
                {
                    text: 'Num attendees',
                    align: 'left',
                    value: 'numAttendees',
                    class: 'w-240 work--header'
                },
                {
                    text: 'Site section',
                    align: 'left',
                    value: 'locationProgramId',
                    class: 'w-240 mobile--header'
                },
                {
                    text: '',
                    align: 'left',
                    value: 'editRecord',
                    fixed: true
                },
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: ['startDate', 'startTime', 'group', 'siteSection'],
            defaultMedication: {
                group: null,
                name: null,
                numAttendees: null,
                locationProgramId: null,
                startDate: format(new Date(), 'yyyy-MM-dd'),
                // tenantId: getInstance().tenantId,
                created: format(new Date(), 'yyyy-MM-dd')
            },
            timeFormat: 'h:mm a',
            fieldNames: ['group', 'name', 'numAttendees', 'locationProgramId', 'startDate'],
            locationProgramItems : []

        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData];
        this.locationProgramItems = this.loggedInUser.locationProgramItems.filter((item) => {
            let itemSections = item.text.split(" ")
            if(itemSections.at(-1).indexOf('PALM') > -1 || itemSections.at(-1).indexOf('CALM') > -1 || item.text === 'ACT - Mentoring') {
                return {...item, label: item.text}
            }
        })
    },
    methods: {
        getDefaultNewObject() {
            return {
                ...cloneDeep(this.defaultMedication)
            }
        },
        getDisplayLocationProgram(programId, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !programId) return ''
            const displayValue = displayList?.find(
                (t) => t.value === programId
            )
            return displayValue != null ? displayValue[prop] : ''
        },
        getLocationProgram(id, arr) {
            let result
            arr.map(e => {
                if(id === e.value) result = e.text
            })
            return result
        },
        filterLocationProgramId(LocationProgram, arr) {
            let result
            arr.filter(e => {
                if(LocationProgram === e.text) result = e.value
            })
            return result
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.eventId !== item.eventId) return td
            })
            this.$apollo
                .mutate({
                    mutation: DELETE_GROUPS,
                    variables: {
                        eventId: item.eventId
                    }
                })
                .then(({data: {events}}) => {
                    this.saving = false
                    this.message = 'Deleted event'
                    this.type = 'success'
                    this.activeRowId = null
                    this.currentNewId = null
                    this.activeRowObj = null
                    this.$emit('event-mutated')
                })
                .catch((error) => {
                    this.message = 'Failed deleting event'
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                })
        },
        toggleEdit(val) {
            if (
                this.activeRowId && Number(this.activeRowId) === Number(val) ||
                this.currentNewId && Number(this.currentNewId) === Number(val) ||
                !this.isEditable
            ) return
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.eventId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.eventId !== this.activeRowId) return item
                })
            }
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if (isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)
                    const data = this.getActiveRow(this.tableDataArr)
                    if (data.numAttendees === '')  data.numAttendees = null

                    delete data.updated
                    delete data.createdBy
                    delete data.tenantId
                    delete data.locationProgram
                    delete data.lastUpdatedBy
                    delete data.created
                    delete data.active
                    const idx = this.tableDataArr.findIndex(t => t.eventId === data.eventId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.eventId

                    delete data.active
                    // data.createdBy = this.loggedInUser.userId
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_GROUP,
                        variables: {
                            event: convertedData
                        }
                    }).then(({ data: { events } }) => {
                        if (events) {
                            const data = {...camelcaseKeys(events, {deep: true})}
                            data.locationProgram = this.getLocationProgram(events.location_program_id, this.locationProgramItems)
                            data.locationProgramId = this.filterLocationProgramId(data.locationProgram, this.locationProgramItems)
                            this.tableDataArr[idx] = data
                        }

                        this.saving = false
                        this.message = 'Saved event item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('event-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving event'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.eventId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        getActiveRow(data) {
            const activeRow = data?.find((item) => {
                return item.eventId === this.activeRowId
            })
            return {...activeRow}
        },
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices,
            siteSections: (state) => state.app.siteSections
        }),
        types() {
            return this.staticChoices['group.types']
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
            this.tableDataArr.map(e => {
                e.locationProgram = this.getLocationProgram(e.locationProgramId, this.locationProgramItems)
            })
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
